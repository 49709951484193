import React from 'react';
import { InputSelect } from '~/components/NovosInputs';
import { useTela } from '../../../TelaContext';
import { useModulos } from '~/pages/CadastroDeTela/data/Modulo';

export const ModuloContainer: React.FC = () => {
  const { formTela, handleDisableSuperAdm } = useTela();

  const { register, formState, control, setValue } = formTela;
  const { errors } = formState;

  const { modulosAtivos, modulosInfo } = useModulos();

  // Ordenar os módulos em ordem alfabética
  const sortedModulos = React.useMemo(
    () =>
      modulosAtivos
        .slice()
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }),
        ),
    [modulosAtivos],
  );

  return (
    <>
      <InputSelect
        label="Módulo"
        placeholder="- Selecione o módulo -"
        name="cod_modulo"
        register={register}
        isError={!!errors.cod_modulo}
        control={control}
        options={sortedModulos}
        changeSelected={(selected) => {
          const selectedValue = Number(selected.value);
          setValue('cod_modulo', selected);

          const moduloEncontrado = modulosInfo.find(
            (modulo) => Number(modulo.cod_modulo) === selectedValue,
          );

          if (moduloEncontrado) {
            const { flg_requer_superadmin } = moduloEncontrado;

            setValue('flg_requer_superadmin', flg_requer_superadmin);
            handleDisableSuperAdm(flg_requer_superadmin);

            if (!flg_requer_superadmin) setValue('flg_requer_admin', false);
          }
        }}
      />
    </>
  );
};
