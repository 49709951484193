import React from 'react';
import { InputSelect } from '~/components/NovosInputs';

import {
  tipoVisualizacao,
  VisualizacaoProps,
} from '../../data/filtros/mockVisualizacao';
import { useTiposDados } from '../../data/TiposDados';
import { TipoDadosProps } from '../../protocols';
import { useTela } from '../../TelaContext';

interface FiltrarTabelaProps {
  type: 'visualizacao' | 'dados';
}

const tipoDadosTodos: TipoDadosProps[] = [{ value: 9999, label: 'Todos' }];

export const FiltrarTabela: React.FC<FiltrarTabelaProps> = ({
  type,
}): JSX.Element => {
  const { formCampo, setFiltrarTabela, filtrarTabela } = useTela();
  const { tipoDados } = useTiposDados();

  const { setValue } = formCampo;

  const arrayTipoDados = Array.from(tipoDados).concat(tipoDadosTodos);

  const value9999Index = arrayTipoDados.findIndex(
    (item) => item.value === 9999,
  );

  if (value9999Index > -1) {
    const value9999Item = arrayTipoDados.splice(value9999Index, 1)[0];
    arrayTipoDados.unshift(value9999Item);
  }

  const data = type === 'visualizacao' ? tipoVisualizacao : arrayTipoDados;

  const { register, formState, control, clearErrors } = formCampo;
  const { errors } = formState;

  const label =
    type === 'visualizacao' ? 'Tipo de visualização' : 'Tipo do dado';

  const name = `tipo_${type}` as const;

  const objectName = type === 'visualizacao' ? 'tipoVisualizacao' : 'tipoDados';

  const onDataChange = (
    selected: VisualizacaoProps | TipoDadosProps | undefined,
  ) => {
    clearErrors(name);

    const updatedFiltrarTabela = {
      ...filtrarTabela,
      [objectName]: selected,
    };

    setFiltrarTabela(updatedFiltrarTabela);
    setValue(name, updatedFiltrarTabela);
  };

  const placeholder =
    filtrarTabela[objectName] === undefined
      ? 'Todos'
      : filtrarTabela[objectName]?.label;

  return (
    <InputSelect
      label={label}
      placeholder={placeholder}
      name={name}
      register={register}
      isError={!!errors[name]}
      control={control}
      options={data}
      changeSelected={onDataChange}
    />
  );
};
