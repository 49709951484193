import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ConfirmButton, CustomButton } from '~/components/Buttons';
import Separator from '~/components/Separator';

import { useTela } from '../../TelaContext';
import { ContainerFiltrarTabela } from '../FiltrarTabela';

export const FieldsActions: React.FC = () => {
  const { setIsShowModalCampo, setIsUpdateCampo, setIsShowOrderFields } =
    useTela();

  const onAddFieldClick = () => {
    setIsShowModalCampo(true);
    setIsUpdateCampo(false);
  };

  const onOrderFieldsClick = () => {
    setIsShowOrderFields(true);
  };

  return (
    <>
      <Row className="d-flex align-items-center mt-2">
        <Col xs={12} sm={8}>
          <Separator labelText="Campos" />
          <ContainerFiltrarTabela />
        </Col>

        <Col sm={4}>
          <div className="d-flex align-items-center justify-content-end">
            <ConfirmButton
              onClick={() => {
                onOrderFieldsClick();
              }}
            >
              Ordenar
            </ConfirmButton>

            <CustomButton
              style={{
                backgroundColor: '#8850bf',
              }}
              onClick={() => {
                onAddFieldClick();
              }}
            >
              Adicionar
            </CustomButton>
          </div>
        </Col>
      </Row>
    </>
  );
};
