import React, { useEffect, useState } from 'react';
import { InputSelect } from '~/components/NovosInputs';

import { icons } from '../../../data';
import { Select } from '../../../protocols';
import { useTela } from '../../../TelaContext';
import { IconeDinamico } from '../../IconeDinamico';

export const IconContainer: React.FC = () => {
  const { formTela } = useTela();

  const { register, formState, control, setValue, watch, resetField } =
    formTela;

  const { errors } = formState;

  const [dataIcons, setDataIcons] = useState<Select[]>([]);

  const watchLibIcon = watch('lib_icon');
  const des_icon = watch('des_icon');

  useEffect(() => {
    if (watchLibIcon) {
      resetField('des_icon');
      const filteredIcons = icons.find(
        (icon) => icon.lib === watchLibIcon.value,
      );

      if (filteredIcons) {
        const iconOptions = filteredIcons.values.map((icon) => ({
          icon: <IconeDinamico nameIcon={icon.label} />,
          label: icon.label,
          value: icon.value,
        }));

        setDataIcons(iconOptions);
      }
    }
  }, [resetField, watchLibIcon]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <span
        style={{
          backgroundColor: '#F4F5F7',
          height: '2.5rem',
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderTopLeftRadius: '0.3125rem',
          borderBottomLeftRadius: '0.3125rem',
          padding: '0.3125rem',
          border: '1px solid #dddddd',
        }}
      >
        <IconeDinamico
          nameIcon={des_icon.value}
          propsIcon={{
            size: '1.3rem',
          }}
        />
      </span>
      <InputSelect
        label="Ícone"
        placeholder="- Selecione o ícone -"
        name="des_icon"
        register={register}
        isError={!!errors.des_icon}
        control={control}
        options={dataIcons}
        changeSelected={(selected) => setValue('des_icon', selected)}
      />
    </div>
  );
};
