import styled from 'styled-components';

export const ContainerRow = styled.div`
  .toglePesquisa {
    max-width: 110px;
  }
  .togleInativo {
    max-width: 100px;
  }
  .togleRequerAdmin {
    max-width: 120px;
  }

  @media screen and (max-width: 1199px) {
    .toglePesquisa {
      max-width: 107px;
    }
    .togleInativo {
      max-width: 70px;
    }
    .togleRequerAdmin {
      max-width: 107px;
    }
    .togleRequerSuperAdmin {
      max-width: 136px;
    }
  }
  @media screen and (max-width: 991px) {
    .toglePesquisa {
      min-width: 140px;
    }

    .togleRequerAdmin {
      margin-top: 10px;
      min-width: 140px;
    }
    .togleRequerSuperAdmin {
      margin-top: 10px;
      min-width: 140px;
    }
  }
  @media screen and (max-width: 767px) {
    .toglePesquisa {
      min-width: 107px;
    }

    .togleRequerAdmin {
      margin-top: 0px;
      min-width: 100px;
    }
    .togleRequerSuperAdmin {
      margin-top: 0px;
      min-width: 130px;
    }
  }
`;
export const AlertBox = styled.div`
  position: relative;
  width: auto;
  transition: 0.2s;
  .alert-class {
    > * {
      border-radius: 4px;
      position: relative;
      border-bottom: 2px solid #cf1919;
      transition: 0.5s;
    }
    p {
      position: absolute;
      right: 0;
      border: none;
      color: #cf1919;
      transition: 0.5s;
    }
  }
`;
