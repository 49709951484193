import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { FiltrarTabela } from './filtrarTabela';

export const ContainerFiltrarTabela: React.FC = () => {
  return (
    <Row>
      <Col>
        <FiltrarTabela type="visualizacao" />
      </Col>
      <Col>
        <FiltrarTabela type="dados" />
      </Col>
    </Row>
  );
};
