import React from 'react';
import { InputSelect } from '~/components/NovosInputs';

import { libIcons } from '../../../data';
import { useTela } from '../../../TelaContext';

export const LibsIconsContainer: React.FC = () => {
  const { formTela } = useTela();

  const { register, formState, control, setValue } = formTela;

  const { errors } = formState;

  return (
    <>
      <InputSelect
        label="Lib do ícone"
        placeholder="- Selecione a lib -"
        name="lib_icon"
        register={register}
        isError={!!errors.lib_icon}
        control={control}
        options={libIcons}
        changeSelected={(selected) => setValue('lib_icon', selected)}
      />
    </>
  );
};
