import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CustomButton } from '~/components/Buttons';
import { InputTextArea } from '~/components/NovosInputs';

import { modeloConsulta } from '../../data';
import { useTela } from '../../TelaContext';
import { FloatButton } from './styles';

export const Consulta: React.FC = () => {
  const { formTela } = useTela();

  const MySwal = withReactContent(Swal);

  const { register, formState, control, setValue, getValues } = formTela;

  const { errors } = formState;

  const onModeloConsultaClick = async () => {
    if (getValues('instrucao').length >= 1) {
      await MySwal.fire({
        icon: 'warning',
        title: ``,
        text: 'Deseja realmente adicionar uma consulta modelo? \n Isso irá limpar sua consulta atual!',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setValue('instrucao', modeloConsulta);
          return setValue('campos', []);
        }
      });
    } else {
      return setValue('instrucao', modeloConsulta);
    }
  };

  return (
    <>
      <InputTextArea
        label="Consulta"
        maxLength={10000}
        placeholder=""
        rows={15}
        name="instrucao"
        register={register}
        control={control}
        autoCorrect="false"
        spellCheck="false"
        style={{
          resize: 'none',
        }}
        isError={!!errors.instrucao}
      />
      <FloatButton>
        <CustomButton
          style={{
            backgroundColor: '#8850bf',
            top: '12px',
            right: '12px',
          }}
          onClick={() => {
            onModeloConsultaClick();
          }}
        >
          Modelo de consulta
        </CustomButton>
      </FloatButton>
    </>
  );
};
