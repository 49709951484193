import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

import { FiltrarTabelaProps } from './protocols/FiltrarTabela';
import { UseFormProps } from './protocols/UseFormProps';
import { schemaFormCampo, schemaFormTela } from './validations';

type TelaContextData = {
  handleClearForm: () => void;
  formTela: UseFormProps;
  setIsUpdateCampo: (value: React.SetStateAction<boolean>) => void;
  isUpdateCampo: boolean;
  setIsShowModalCampo: (value: React.SetStateAction<boolean>) => void;
  isShowModalCampo: boolean;
  setIsShowOrderFields: (value: React.SetStateAction<boolean>) => void;
  isShowOrderFields: boolean;
  generateIdCampo: () => number;
  formCampo: UseFormProps;
  setIsUpdate: (value: React.SetStateAction<boolean>) => void;
  isUpdate: boolean;
  setFiltrarTabela: (value: FiltrarTabelaProps) => void;
  filtrarTabela: FiltrarTabelaProps;
  handleCodCampo: (cod: number | undefined) => void;
  handleIdCampo: (id: string | undefined) => void;
  codCampo: number | undefined;
  idCampoGerado: string | undefined;
  disableSuperAdm: boolean;
  handleDisableSuperAdm: (disable: boolean) => void;
};

type FormContextData = {
  register: any;
  handleSubmit: any;
  formState: any;
  setValue: any;
  control: any;
  reset: any;
  getValues: any;
  watch: any;

  //  formLojaFinalizadora
  handleSubmitFinalizadora: any;
  formStateFinalizadora: any;
  FinalizadoraRegister: any;
  FinalizadoraControl: any;
  getValuesFinalizadora: any;
  setValueFinalizadora: any;
};

export const FormContext = createContext({} as FormContextData);
export const TelaContext = createContext({} as TelaContextData);

interface TelaContextProviderProps {
  children: ReactNode;
}

export function TelaContextProvider({
  children,
}: TelaContextProviderProps): JSX.Element {
  const [isShowModalCampo, setIsShowModalCampo] = useState(false);
  const [isUpdateCampo, setIsUpdateCampo] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [filtrarTabela, setFiltrarTabela] = useState<FiltrarTabelaProps>({
    tipoVisualizacao: undefined,
    tipoDados: undefined,
  });
  const [disableSuperAdm, setDisableSuperAdm] = useState(false);

  const formTelaDefault = {
    des_tela: '',
    cod_modulo: { label: undefined, value: undefined },
    flg_inativo: false,
    flg_abre_pesquisa: false,
    flg_requer_admin: false,
    flg_requer_superadmin: false,
    des_funcao: '',
    label_menu: '',
    lib_icon: { label: undefined, value: undefined },
    des_icon: { label: undefined, value: undefined },
    link_material: '',
    instrucao: '',
    campos: [],
  };

  const formCampoDefault = {
    des_campo: '',
    nome_bd: '',
    cod_tipo_dados: { label: undefined, value: undefined },
    cod_tipo_info: { label: undefined, value: undefined },
    flg_filtro: false,
    flg_visualizacao: false,
    flg_filtro_obrigatorio: false,
    tipo_agregador_tot: { label: undefined, value: undefined },
    sinonimos: [],
    val_largura: 100,
  };

  const {
    register: registerFormTela,
    getValues: getValuesFormTela,
    handleSubmit: handleSubmitFormTela,
    formState: formStateFormTela,
    control: controlFormTela,
    setValue: setValueFormTela,
    watch: watchFormTela,
    reset: resetFormTela,
    setFocus: setFocusFormTela,
    clearErrors: clearErrorsFormTela,
    setError: setErrorFormTela,
    resetField: resetFieldFormTela,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(schemaFormTela),
  });

  const formTela: UseFormProps = {
    register: registerFormTela,
    getValues: getValuesFormTela,
    handleSubmit: handleSubmitFormTela,
    formState: formStateFormTela,
    control: controlFormTela,
    setValue: setValueFormTela,
    watch: watchFormTela,
    reset: resetFormTela,
    setFocus: setFocusFormTela,
    clearErrors: clearErrorsFormTela,
    setError: setErrorFormTela,
    resetField: resetFieldFormTela,
  };

  useEffect(() => {
    handleClearForm();
  }, []);

  const {
    register: registerFormCampo,
    getValues: getValuesFormCampo,
    handleSubmit: handleSubmitFormCampo,
    formState: formStateFormCampo,
    control: controlFormCampo,
    setValue: setValueFormCampo,
    watch: watchFormCampo,
    reset: resetFormCampo,
    setFocus: setFocusFormCampo,
    clearErrors: clearErrorsFormCampo,
    setError: setErrorFormCampo,
    resetField: resetFieldFormCampo,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(schemaFormCampo),
  });

  const formCampo: UseFormProps = {
    register: registerFormCampo,
    getValues: getValuesFormCampo,
    handleSubmit: handleSubmitFormCampo,
    formState: formStateFormCampo,
    control: controlFormCampo,
    setValue: setValueFormCampo,
    watch: watchFormCampo,
    reset: resetFormCampo,
    setFocus: setFocusFormCampo,
    clearErrors: clearErrorsFormCampo,
    setError: setErrorFormCampo,
    resetField: resetFieldFormCampo,
  };
  const [isShowOrderFields, setIsShowOrderFields] = useState(false);
  const [idCampo, setIdCampo] = useState(1);
  const [codCampo, setCodCampo] = useState<number | undefined>(undefined);
  const [idCampoGerado, setIdCampoGerado] = useState<string | undefined>(
    undefined,
  );

  const generateIdCampo = () => {
    setIdCampo((prevstate) => prevstate + 1);
    return idCampo;
  };

  const handleCodCampo = useCallback((cod: number | undefined) => {
    setCodCampo(cod);
  }, []);

  const handleIdCampo = useCallback((id: string | undefined) => {
    setIdCampoGerado(id);
  }, []);

  const handleDisableSuperAdm = useCallback((disable: boolean) => {
    setDisableSuperAdm(disable);
  }, []);

  const handleClearForm = (): void => {
    resetFormCampo(formCampoDefault);
    clearErrorsFormCampo();
    resetFormTela(formTelaDefault);
    clearErrorsFormTela();
    setDisableSuperAdm(false);
  };

  return (
    <TelaContext.Provider
      value={{
        handleClearForm,
        formTela,
        setIsUpdateCampo,
        isUpdateCampo,
        setIsShowModalCampo,
        isShowModalCampo,
        formCampo,
        setIsShowOrderFields,
        isShowOrderFields,
        generateIdCampo,
        setIsUpdate,
        isUpdate,
        setFiltrarTabela,
        filtrarTabela,
        handleCodCampo,
        codCampo,
        handleIdCampo,
        idCampoGerado,
        disableSuperAdm,
        handleDisableSuperAdm,
      }}
    >
      {children}
    </TelaContext.Provider>
  );
}

export const useTela = (): TelaContextData => {
  return useContext(TelaContext);
};
