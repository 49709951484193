import { GridRowParams } from '@material-ui/data-grid';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';
import api from '~/services/api';

import { useTela } from './TelaContext';
import { Form, Table } from './components';
import { FieldsActions } from './components/FieldsActions';
import { ModalFormCampo } from './components/ModalFormCampo';
import { OrderFieldsListBox } from './components/OrderFieldsListBox';
import { useIcons } from './data';
import { useModulos } from './data/Modulo';
import { useTelaData } from './data/Tela';
import { useTiposDados } from './data/TiposDados';
import { useTiposInformacoes } from './data/TiposInformacoes';
import { tiposAgregadores } from './data/tiposAgregadores';
import { Container } from './styles';
import { serializeData } from './utils/serializeData';

const TelaContent: React.FC = () => {
  const [showSearch, setShowSearch] = useState(true);
  const [codTela, setCodTela] = useState(undefined);

  const {
    handleClearForm,
    formTela,
    isUpdate,
    setIsUpdate,
    setFiltrarTabela,
    handleDisableSuperAdm,
  } = useTela();

  const { modulos } = useModulos();

  const { findTela } = useTelaData();
  const { tipoDados } = useTiposDados();
  const { getIconsFromLib } = useIcons();
  const { tiposInformacoes } = useTiposInformacoes();

  const { handleSubmit, reset, getValues } = formTela;

  const onRowClick = async (param: GridRowParams) => {
    const { cod_tela } = param.row;
    const tela = await findTela(cod_tela);
    const serializedData = await serializeData({
      data: tela,
      modulos,
      getIconsFromLib,
      tipoDados,
      tiposInformacoes,
      tiposAgregadores,
    });
    reset({
      ...serializedData,
    });
    setCodTela(cod_tela);
    setIsUpdate(true);
    setShowSearch(false);

    const res = await api.get('/modulo');
    const { data, success } = res.data;

    if (success) {
      const moduloEncontrado = data.find(
        (modulo: any) => Number(modulo.cod_modulo) === Number(tela.cod_modulo),
      );

      if (moduloEncontrado)
        handleDisableSuperAdm(moduloEncontrado.flg_requer_superadmin);
    }
  };

  const onCancel = async () => {
    setCodTela(undefined);
    handleClearForm();
    setShowSearch(true);
    setFiltrarTabela({ tipoVisualizacao: undefined, tipoDados: undefined });
  };

  const onNew = (): void => {
    setCodTela(undefined);
    handleClearForm();
    setIsUpdate(false);
    setShowSearch(false);
    setFiltrarTabela({ tipoVisualizacao: undefined, tipoDados: undefined });
  };

  const onReturnSearch = () => {
    setCodTela(undefined);
    setIsUpdate(false);
    handleClearForm();
    setShowSearch(true);
    setFiltrarTabela({ tipoVisualizacao: undefined, tipoDados: undefined });
  };

  const onSave = handleSubmit(async (formData: any) => {
    let { campos } = formData;
    const { cod_modulo, lib_icon, des_icon } = formData;

    campos = campos.map((campo: any) => {
      const { cod_tipo_dados, cod_tipo_info, tipo_agregador_tot } = campo;

      return {
        ...campo,
        cod_tipo_dados: cod_tipo_dados ? cod_tipo_dados.value : undefined,
        cod_tipo_info: cod_tipo_info ? cod_tipo_info.value : undefined,
        tipo_agregador_tot: tipo_agregador_tot
          ? tipo_agregador_tot.value
          : undefined,
      };
    });

    formData.campos = campos;

    const upsert = {
      ...formData,
      link_material: formData.link_material.trim(),
      cod_modulo: cod_modulo.value,
      lib_icon: lib_icon.value,
      des_icon: des_icon.value,
    };

    if (isUpdate) {
      const id = getValues('cod_tela');
      const res = await api.put(`/tela/${id}`, upsert);
      const { message } = res.data;
      onReturnSearch();
      return toast.success(message);
    }

    const res = await api.post('/tela', upsert);
    const { message } = res.data;
    setCodTela(undefined);
    setIsUpdate(false);
    handleClearForm();
    return toast.success(message);
  });

  return (
    <Container>
      {showSearch ? (
        <Search
          codTela={222}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          orderTelas
        />
      ) : (
        <FormDefault
          codTela={222}
          title="Tela"
          codigoRegistro={[{ value: codTela, des_campo: 'Código' }]}
          onSave={() => onSave()}
          isUpdate={isUpdate}
          onCancel={() => onCancel()}
          onNew={() => {
            onNew();
          }}
          isDelete={false}
          onDelete={() => null}
          onClearFields={() => {
            handleClearForm();
          }}
          onReturnSearch={() => onReturnSearch()}
          showSwitch={false}
        >
          <Form />
          <FieldsActions />
          <Table />
          <ModalFormCampo />
          <OrderFieldsListBox />
        </FormDefault>
      )}
    </Container>
  );
};

export default TelaContent;
